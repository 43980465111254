import { ErrorHandler } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { registerLocaleData } from '@angular/common';
import localeAR from '@angular/common/locales/es-AR';
localeAR[localeAR.length - 5][2] = '¤#,##0.00'; // Remove space between symbol and number
localeAR[localeAR.length - 2]['USD'] = ['$', '$']; // Change default symbol
registerLocaleData(localeAR);
Sentry.init({
    dsn: 'https://298d2e36233048fe841f7d57dc314aeb@sentry.io/1848225',
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        // console.error(error);
        Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
var ɵ0 = function (req) {
    return req.url === '/api/auth/refresh-token';
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
