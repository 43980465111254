import { OperatorBranch } from './operator_branch.model';
var Operator = /** @class */ (function () {
    function Operator(init) {
        this.operator_branches = [];
        this.activeBranch = null;
        Object.assign(this, init);
        if (this.operator_branches.length > 0) {
            this.activeBranch = new OperatorBranch(this.operator_branches[0]);
        }
    }
    Object.defineProperty(Operator.prototype, "permissions", {
        get: function () {
            return this.activeBranch && this.activeBranch.permission_group && this.activeBranch.permission_group.permissions ? this.activeBranch.permission_group.permissions : null;
        },
        enumerable: true,
        configurable: true
    });
    Operator.prototype.canAccessTo = function (key) {
        var variables = {
            exchange: !this.permissions ? true : !!this.permissions.exchange.can_view,
            operate: !this.permissions ? true : !!this.permissions.operate.can_view,
            clients: !this.permissions ? true : !!this.permissions.clients.can_view,
            history: !this.permissions ? true : !!this.permissions.history.can_view,
            history_provider: !this.permissions ? true : !!this.permissions.history_provider.can_view,
            products: !this.permissions ? true : !!this.permissions.products.can_view,
            products_shop: !this.permissions ? true : !!this.permissions.products_shop.can_view,
            statistics: !this.permissions ? true : !!this.permissions.statistics.can_view,
            validate: !this.permissions ? true : !!this.permissions.validate.can_view,
            integrations: !this.permissions ? true : !!this.permissions.integrations.can_view,
            levels: !this.permissions ? true : !!this.permissions.levels.can_view,
            surveys: !this.permissions ? true : !!this.permissions.surveys.can_view,
        };
        return variables[key];
    };
    Object.defineProperty(Operator.prototype, "isAdmin", {
        get: function () {
            return this.role === 'admin';
        },
        enumerable: true,
        configurable: true
    });
    return Operator;
}());
export { Operator };
