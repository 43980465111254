import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionOperate = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionOperate, _super);
    function PermissionOperate() {
        return _super.call(this) || this;
    }
    return PermissionOperate;
}(PermissionBase));
export { PermissionOperate };
