import * as tslib_1 from "tslib";
import { NbLogoutComponent } from '@nebular/auth';
var LogoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LogoutComponent, _super);
    function LogoutComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LogoutComponent.prototype.logout = function (strategy) {
        _super.prototype.logout.call(this, strategy);
        localStorage.clear();
        localStorage.setItem('show_popup', 'true');
    };
    return LogoutComponent;
}(NbLogoutComponent));
export { LogoutComponent };
