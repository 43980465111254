import * as tslib_1 from "tslib";
import { NbAuthResult, NbOAuth2AuthStrategy, } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
var Oauth2AuthStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(Oauth2AuthStrategy, _super);
    function Oauth2AuthStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Oauth2AuthStrategy.setup = function (options) {
        return [Oauth2AuthStrategy, options];
    };
    Oauth2AuthStrategy.prototype.passwordToken = function (username, password) {
        return _super.prototype.passwordToken.call(this, username, password)
            .pipe(map(function (res) { return new NbAuthResult(res.isSuccess(), res.getResponse(), res.getRedirect(), res.getResponse().error ? [res.getResponse().error.message] : null, res.getMessages(), res.getToken()); }));
    };
    Oauth2AuthStrategy.prototype.requestPassword = function (data) {
        var _this = this;
        var module = 'requestPass';
        var method = this.getOption(module + ".method");
        var url = this.getActionEndpoint(module);
        return this.http
            .request(method, url, { body: data, observe: 'response' })
            .pipe(map(function (res) { return new NbAuthResult(true, res, _this.getOption(module + ".redirect.success"), [], res.body['status']); }))
            .catch(function (res) {
            return _this.handleResponseError(res);
        });
    };
    Oauth2AuthStrategy.prototype.resetPassword = function (data) {
        var _this = this;
        data['token'] = this.route.snapshot.queryParams.token;
        data['business_id'] = this.route.snapshot.queryParams.business_id;
        data['email'] = this.route.snapshot.queryParams.email;
        data['dni'] = this.route.snapshot.queryParams.dni;
        data['password_confirmation'] = data.confirmPassword;
        return this.http.post('oauth/password/reset', data)
            .pipe(map(function (res) {
            return new NbAuthResult(true, res, _this.getOption("redirect.success"), ['Ocurrió un error, intentelo nuevamente'], res['message']);
        }), catchError(function (res) {
            return _this.handleResponseError(res);
        }));
    };
    Oauth2AuthStrategy.prototype.register = function (data) {
        var _this = this;
        var module = 'register';
        var method = this.getOption(module + ".method");
        var url = this.getActionEndpoint(module);
        var requireValidToken = this.getOption(module + ".requireValidToken");
        data.user.password_confirmation = data.user.confirmPassword;
        return this.http.request(method, url, { body: data, observe: 'response' })
            .pipe(map(function (res) {
            return new NbAuthResult(true, res, _this.getOption(module + ".redirect.success"), [], ['¡Login Exitoso!'], _this.createToken(res.body, requireValidToken));
        }), catchError(function (res) {
            return _this.handleResponseError(res);
        }));
    };
    Oauth2AuthStrategy.prototype.logout = function () {
        return Observable.of(new NbAuthResult(true, {}, '/auth/login'));
    };
    return Oauth2AuthStrategy;
}(NbOAuth2AuthStrategy));
export { Oauth2AuthStrategy };
