import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HistoryService = /** @class */ (function () {
    function HistoryService(http) {
        this.http = http;
    }
    HistoryService.prototype.getPaginationResult = function (params) {
        return this.http
            .get('history', { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (event) {
                return event.type === 'purchase' ? new Purchase(event) : new Read(event);
            });
            return new PaginationResult(pagination_result);
        }));
    };
    HistoryService.prototype.getProviderPaginationResult = function (params) {
        return this.http
            .get('history/provider', { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            return new PaginationResult(pagination_result);
        }));
    };
    HistoryService.prototype.get = function (params) {
        if (params === void 0) { params = {}; }
        return this.http
            .get('history', { params: params })
            .pipe(map(function (events) { return events.map(function (event) { return event.type === 'purchase' ? new Purchase(event) : new Read(event); }); }));
    };
    HistoryService.prototype.cancelRead = function (read_id) {
        return this.http.delete("reads/" + read_id);
    };
    HistoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.ɵɵinject(i1.HttpClient)); }, token: HistoryService, providedIn: "root" });
    return HistoryService;
}());
export { HistoryService };
