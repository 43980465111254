import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Client } from '../../_models/client.model';
import { TagService } from '../../_services/tag.service';
var ConfirmSubstractComponent = /** @class */ (function () {
    function ConfirmSubstractComponent(ref, formBuilder, tagService) {
        this.ref = ref;
        this.formBuilder = formBuilder;
        this.tagService = tagService;
        this.title = 'Sustraer puntos';
        this.acceptButtonText = 'Restar';
        this.acceptButtonStatus = 'primary';
        this.cancelButtonText = 'Cancelar';
        this.cancelButtonStatus = 'basic';
        this.submitted = false;
    }
    ConfirmSubstractComponent.prototype.ngOnInit = function () {
        var group = {
            score: [null, [Validators.required]],
        };
        this.form = this.formBuilder.group(group);
    };
    Object.defineProperty(ConfirmSubstractComponent.prototype, "controls", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    ConfirmSubstractComponent.prototype.save = function () {
        var _this = this;
        var amount = 0 - Math.round(+this.form.controls['score'].value / +this.amount);
        var data = {
            score: 0 - this.form.controls['score'].value,
            status: 'active',
            amount: amount,
            type: 'substraction',
            business_id: this.business_id,
        };
        if (this.email && this.email.length > 0)
            data.email = this.email;
        if (this.dni && this.dni.length > 0)
            data.dni = this.dni;
        this.submitted = true;
        this.tagService.substract(data)
            .subscribe(function (resp) {
            var value = {
                id: _this.id,
                score: _this.form.controls['score'].value,
            };
            _this.submitted = false;
            _this.accept(value);
        });
    };
    ConfirmSubstractComponent.prototype.cancel = function () {
        this.ref.close(null);
    };
    ConfirmSubstractComponent.prototype.accept = function (value) {
        this.ref.close(value);
    };
    return ConfirmSubstractComponent;
}());
export { ConfirmSubstractComponent };
