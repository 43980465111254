import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { User } from '../../_models/user.model';
import { UserService } from '../../_services/user.service';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(userService, ref, formBuilder, toastrService) {
        this.userService = userService;
        this.ref = ref;
        this.formBuilder = formBuilder;
        this.toastrService = toastrService;
        this.title = 'Cambio de contraseña';
        this.acceptButtonText = 'Guardar';
        this.acceptButtonStatus = 'primary';
        this.cancelButtonText = 'Cancelar';
        this.cancelButtonStatus = 'basic';
        this.useOutput = false;
        this.onPasswordUpdate = new EventEmitter();
        this.saving = false;
        this.submitted = false;
        this.form = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(40)]],
            confirmPassword: ['', [Validators.required]],
        });
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.current().subscribe(function (user) {
            _this.user = user;
        });
    };
    Object.defineProperty(ChangePasswordComponent.prototype, "controls", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    ChangePasswordComponent.prototype.save = function () {
        var _this = this;
        if (this.useOutput) {
            this.onPasswordUpdate.emit(this.form.value.password);
            return;
        }
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.saving = true;
        this.userService.save(new User({ 'id': this.user.id, 'password': this.form.value.password })).subscribe(function () {
            _this.toastrService.success('La contraseña fue cambiada con éxito.', '¡Bien!');
            _this.accept();
        }, function (err) {
            _this.saving = false;
        });
    };
    ChangePasswordComponent.prototype.cancel = function () {
        this.ref.close(false);
    };
    ChangePasswordComponent.prototype.accept = function () {
        this.ref.close(true);
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
