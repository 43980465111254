import { ExtraOptions, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterTokenResolverService } from './register-token-resolver.service';
import { RegisterComponent } from './auth/register/register.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { AuthCallbackComponent } from './auth/callback/callback.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UnsubscribeComponent } from './auth/unsubscribe/unsubscribe.component';
import { BusinessesCurrentResolver } from './admin/businesses/_resolvers/businesses-current.resolver';
import { BusinessesCurrentForceResolver } from './admin/businesses/_resolvers/businesses-current-force.resolver';
var ɵ0 = function () { return import("./frontend/frontend.module.ngfactory").then(function (m) { return m.FrontendModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/pages.module.ngfactory").then(function (m) { return m.PagesModuleNgFactory; }); }, ɵ2 = function () { return import("./admin/admin.module.ngfactory").then(function (m) { return m.AdminModuleNgFactory; }); }, ɵ3 = function () { return import("./public/public.module.ngfactory").then(function (m) { return m.PublicModuleNgFactory; }); };
var routes = [
    {
        path: '',
        resolve: {
            business: BusinessesCurrentResolver,
        },
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                loadChildren: ɵ0,
            }, {
                path: 'business',
                loadChildren: ɵ1,
            }, {
                path: 'admin',
                loadChildren: ɵ2,
            }, {
                path: 'public',
                loadChildren: ɵ3,
            }, {
                path: 'login',
                component: LoginComponent,
            }, {
                path: 'auth',
                component: AuthComponent,
                resolve: {
                    business: BusinessesCurrentForceResolver,
                },
                children: [
                    {
                        path: '',
                        component: LoginComponent,
                    }, {
                        path: 'login',
                        component: LoginComponent,
                    }, {
                        path: 'register/:token/:id',
                        component: RegisterComponent,
                        resolve: {
                            user: RegisterTokenResolverService,
                        },
                    }, {
                        path: 'register',
                        component: RegisterComponent,
                    }, {
                        path: 'logout',
                        component: LogoutComponent,
                    }, {
                        path: 'request-password',
                        component: RequestPasswordComponent,
                    }, {
                        path: 'reset-password',
                        component: ResetPasswordComponent,
                    }, {
                        path: 'callback/:social',
                        component: AuthCallbackComponent,
                    }, {
                        path: 'unsubscribe',
                        component: UnsubscribeComponent,
                    },
                ],
            },
        ],
    },
    { path: '**', redirectTo: '' },
];
var config = {
    useHash: false,
    paramsInheritanceStrategy: 'always',
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
