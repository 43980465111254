import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { NbAuthService, NbRegisterComponent, NbAuthResult } from '@nebular/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModel, NgForm } from '@angular/forms';
import { User } from '../../_models/user.model';
import { takeWhile } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';
import { BusinessService } from '../../_services/business.service';
import { OperatorService } from '../../_services/operator.service';
import { ClientService } from '../../_services/client.service';
var RegisterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RegisterComponent, _super);
    function RegisterComponent(service, options, cd, router, route, userService, operatorService, clientService, businessService, document) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, cd, router) || this;
        _this.service = service;
        _this.options = options;
        _this.cd = cd;
        _this.router = router;
        _this.route = route;
        _this.userService = userService;
        _this.operatorService = operatorService;
        _this.clientService = clientService;
        _this.businessService = businessService;
        _this.document = document;
        _this.dniOrCuit = 'DNI';
        _this.socialAlive = true;
        _this.businessUser = false;
        _this.then = _this.route.snapshot.params.then;
        return _this;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user.gender = '0';
        this.business = this.businessService.business;
        if (this.business.login_with_dni_text) {
            this.dniOrCuit = 'DNI';
        }
        else if (this.business.login_with_cuit_text) {
            this.dniOrCuit = 'CUIT';
        }
        this.route.data
            .subscribe(function (data) {
            var _a;
            if (data.user) {
                if (data.user.phone)
                    _this.user.phone = data.user.phone;
                _this.businessUser = data.user.role === 'business';
                _this.user = tslib_1.__assign({}, data.user, { gender: _this.user.gender });
                if (data.user.birthday) {
                    _this.user.birthday = new Date(data.user.birthday);
                }
                if (_this.user.first_name.indexOf(' ') > 0 && _this.user.last_name === '') {
                    _a = _this.user.first_name.split(' ', 2), _this.user.first_name = _a[0], _this.user.last_name = _a[1];
                }
            }
        });
    };
    RegisterComponent.prototype.register = function () {
        var _this = this;
        this.errors = this.messages = [];
        this.submitted = true;
        var data = {
            user: this.user.birthday ? tslib_1.__assign({}, this.user, { birthday: this.user.birthday.toISOString().split('T')[0] }) : this.user,
        };
        if (this.then) {
            data.then = this.then;
        }
        this.service
            .register(this.strategy, data)
            .subscribe(function (result) {
            if (result.isSuccess()) {
                _this.messages = result.getMessages();
                var user = new User(result.getResponse().body);
                if (user.role === 'business') {
                    _this.document.location.href = user.business.link + "/auth/login;status=verified";
                }
                else {
                    var params = {};
                    if (_this.then) {
                        params.then = _this.then;
                    }
                    if (data.user.id) {
                        // Update user (login)
                        _this.service
                            .authenticate(_this.strategy, _this.user)
                            .subscribe(function (result2) {
                            _this.userService.clear();
                            _this.operatorService.clear();
                            _this.clientService.clear();
                            _this.businessService.clear();
                            if (result2.isSuccess()) {
                                _this.messages = result2.getMessages();
                                if (_this.then) {
                                    _this.router.navigate([_this.then]);
                                }
                                else {
                                    _this.router.navigate(['/']);
                                }
                            }
                        });
                    }
                    else {
                        // New user (redirect)
                        params.status = 'verification_pending';
                        _this.router.navigate(['/auth/login', params]);
                    }
                }
            }
            else {
                _this.submitted = false;
                _this.errors = result.getErrors();
                _this.serverErrors = result.getResponse().error.errors;
                Object
                    .keys(_this.serverErrors)
                    .forEach(function (key) {
                    var message = _this.serverErrors[key][0];
                    var control = _this.form.controls[key];
                    if (control) {
                        control.setErrors({ server: message });
                    }
                    _this.errors = [];
                });
            }
        });
    };
    RegisterComponent.prototype.socialLogin = function (social) {
        var _this = this;
        localStorage.setItem('social_redirect_uri', this.route.snapshot.params.then);
        this.service
            .authenticate(social)
            .pipe(takeWhile(function () { return _this.socialAlive; }))
            .subscribe(function (authResult) {
        });
    };
    RegisterComponent.prototype.filterNumbers = function (event, invert) {
        if (invert === void 0) { invert = false; }
        if (event.key === 'Backspace')
            return;
        var test = /[0-9]/.test(event.key);
        if ((!invert && test) || (invert && !test))
            event.preventDefault();
    };
    return RegisterComponent;
}(NbRegisterComponent));
export { RegisterComponent };
