import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionProductsShop = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionProductsShop, _super);
    function PermissionProductsShop() {
        var _this = _super.call(this) || this;
        _this.can_add = false;
        _this.can_edit = false;
        _this.can_remove = false;
        _this.can_change_status = false;
        return _this;
    }
    return PermissionProductsShop;
}(PermissionBase));
export { PermissionProductsShop };
