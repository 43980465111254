import * as tslib_1 from "tslib";
import { NbAuthTokenParceler } from '@nebular/auth';
import { environment } from '../../environments/environment';
import * as Cookies from 'js-cookie';
var NbTokenStorage = /** @class */ (function () {
    function NbTokenStorage() {
    }
    return NbTokenStorage;
}());
export { NbTokenStorage };
var NbTokenCookieStorage = /** @class */ (function (_super) {
    tslib_1.__extends(NbTokenCookieStorage, _super);
    function NbTokenCookieStorage(parceler) {
        var _this = _super.call(this) || this;
        _this.parceler = parceler;
        _this.key = 'puntos_auth';
        _this.domain = '.puntos.com';
        _this.path = '/';
        if (!environment.production && environment.develop) {
            _this.domain = window.location.hostname;
        }
        else {
            var url = new URL(environment.frontendBaseUrl);
            _this.domain = url.host.split(':').shift();
        }
        _this.key = _this.key + ((environment.production) ? '_prod' : '_demo');
        return _this;
    }
    NbTokenCookieStorage.prototype.get = function () {
        var raw = Cookies.get(this.key);
        return this.parceler.unwrap(raw);
    };
    NbTokenCookieStorage.prototype.set = function (token) {
        var raw = this.parceler.wrap(token);
        Cookies.set(this.key, raw, { expires: 3600, path: this.path, domain: this.domain, secure: true, sameSite: 'None' });
    };
    NbTokenCookieStorage.prototype.clear = function () {
        Cookies.remove(this.key, { path: this.path, domain: this.domain });
    };
    return NbTokenCookieStorage;
}(NbTokenStorage));
export { NbTokenCookieStorage };
