var Plan = /** @class */ (function () {
    function Plan(init) {
        this.enabled = false;
        this.public = false;
        this.default = false;
        this.use_business_brand_in_emails = false;
        this.enable_control_stock = false;
        this.allow_categories = false;
        this.allow_exchange_products = false;
        this.allow_exchange_money = false;
        this.allow_operate_points_by_qr = false;
        this.allow_operate_points_by_email = false;
        this.allow_operate_points_by_whatsapp = false;
        this.allow_operate_points_by_link = false;
        this.allow_operate_points_by_affiliate_card = false;
        this.allow_operate_points_by_client = false;
        this.allow_operators = false;
        this.allow_permissions_groups = false;
        this.allow_integrations = false;
        this.allow_provider_mode = false;
        this.hide_products_shop = false;
        this.allow_levels = false;
        this.keep_levels = false;
        this.allow_surveys = false;
        this.allow_marketplace = false;
        this.allow_exchange_limits = false;
        this.businesses = [];
        Object.assign(this, init);
    }
    Object.defineProperty(Plan.prototype, "canOperatePoints", {
        get: function () {
            return this.allow_operate_points_by_affiliate_card ||
                this.allow_operate_points_by_client ||
                this.allow_operate_points_by_email ||
                this.allow_operate_points_by_link ||
                this.allow_operate_points_by_qr ||
                this.allow_operate_points_by_whatsapp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Plan.prototype, "canExchangePoints", {
        get: function () {
            return this.allow_exchange_money || this.allow_exchange_products;
        },
        enumerable: true,
        configurable: true
    });
    return Plan;
}());
export { Plan };
