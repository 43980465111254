import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Client } from '../_models/client.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientService = /** @class */ (function () {
    function ClientService(http) {
        var _this = this;
        this.http = http;
        this.pagination_cache = [];
        this.search = function (query) {
            var params = { query: query };
            return _this.http.get('clients/search', { params: params });
        };
    }
    ClientService.prototype.current = function () {
        return this.http.get('clients/current').pipe(map(function (response) {
            if (response.success) {
                var actualClient = new Client(response.data);
                if (actualClient && actualClient.level) {
                    document.documentElement.style.setProperty('--primary-level-color', actualClient.level.primary_color);
                    document.documentElement.style.setProperty('--secondary-level-color', actualClient.level.secondary_color);
                }
                return actualClient;
            }
            else {
                return null;
            }
        }));
    };
    ClientService.prototype.clear = function () {
        // this._operator = null;
        // this._operator.next(null);
    };
    ClientService.prototype.getPaginationResult = function (params) {
        var _this = this;
        return this.http.get('clients', { params: params }).pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (client) {
                return new Client(client);
            });
            _this.pagination_cache = pagination_result.data;
            return new PaginationResult(pagination_result);
        }));
    };
    ClientService.prototype.downloadXlsx = function (params) {
        return this.http
            .get('users/xlsx', { responseType: 'arraybuffer', params: params })
            .subscribe(function (res) {
            var url = window.URL.createObjectURL(new Blob([res]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Clientes.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };
    ClientService.prototype.get = function (id) {
        return this.http
            .get("clients/" + id)
            .pipe(map(function (response) { return new Client(response); }));
    };
    ClientService.prototype.save = function (client) {
        var method = client.id ? 'put' : 'post';
        var url = 'clients';
        if (method === 'put') {
            url += '/' + client.id;
        }
        return this.http.request(method, url, { body: client });
    };
    ClientService.prototype.delete = function (client) {
        return this.http.delete("clients/" + client.id);
    };
    ClientService.prototype.changeStatus = function (client, newStatus) {
        return this.http.put("clients/" + client.id + "/status", {
            status: newStatus,
        });
    };
    ClientService.prototype.updateUserLevel = function (client, level) {
        return this.http.put("clients/" + client.id + "/level/" + level.id, {});
    };
    ClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
export { ClientService };
