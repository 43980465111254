import { BusinessService } from '../../../_services/business.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../_services/business.service";
var BusinessesCurrentResolver = /** @class */ (function () {
    function BusinessesCurrentResolver(businessService) {
        this.businessService = businessService;
    }
    BusinessesCurrentResolver.prototype.resolve = function (route) {
        return this.businessService.current();
    };
    BusinessesCurrentResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessesCurrentResolver_Factory() { return new BusinessesCurrentResolver(i0.ɵɵinject(i1.BusinessService)); }, token: BusinessesCurrentResolver, providedIn: "root" });
    return BusinessesCurrentResolver;
}());
export { BusinessesCurrentResolver };
