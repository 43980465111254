import { OnInit, EventEmitter } from '@angular/core';
import { CategoryChange, CategoryChangeType } from '../../../_models/category.model';
import { ProductService } from '../../../_services/product.service';
var ProductCategorySelectorComponent = /** @class */ (function () {
    function ProductCategorySelectorComponent(_productService) {
        this._productService = _productService;
        this.displaySelectAll = true;
        this.categories = [];
        this.collapsed = false;
        this.selected = new EventEmitter();
    }
    ProductCategorySelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectAll = this.displaySelectAll;
        this._productService.reloadCategories.subscribe(function (categories) {
            _this.selectAll = false;
        });
        this.qtySelected = this.categories.filter(function (c) { return c.selected; }).length;
    };
    Object.defineProperty(ProductCategorySelectorComponent.prototype, "SelectAll", {
        get: function () {
            return this.selectAll;
        },
        enumerable: true,
        configurable: true
    });
    ProductCategorySelectorComponent.prototype.categoryClick = function (idx) {
        var eventResult = null;
        if (idx != null) {
            this.selectAll = false;
            this.categories[idx].selected = !this.categories[idx].selected;
            if (this.categories[idx].selected) {
                this.qtySelected++;
            }
            else {
                this.qtySelected--;
            }
            if (this.qtySelected === 0 && this.displaySelectAll) {
                this.selectAll = true;
            }
            eventResult = CategoryChange
                .fromCategory(this.categories[idx], this.categories[idx].selected ? CategoryChangeType.Add : CategoryChangeType.Remove);
        }
        else {
            this.selectAll = true;
            this.qtySelected = 0;
            this.categories.forEach(function (c) { return c.selected = false; });
        }
        this.selected.emit(eventResult);
    };
    ProductCategorySelectorComponent.prototype.isSelected = function (idx) {
        return this.categories[idx].selected && !this.selectAll;
    };
    return ProductCategorySelectorComponent;
}());
export { ProductCategorySelectorComponent };
