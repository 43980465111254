import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionValidate = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionValidate, _super);
    function PermissionValidate() {
        var _this = _super.call(this) || this;
        _this.can_validate = false;
        return _this;
    }
    return PermissionValidate;
}(PermissionBase));
export { PermissionValidate };
