import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Operator } from '../_models/operator.model';
import { Observable, ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OperatorService = /** @class */ (function () {
    function OperatorService(http) {
        this.http = http;
        this.pagination_cache = [];
        this._operator = new ReplaySubject(1);
        this.operator$ = this._operator.asObservable();
    }
    OperatorService.prototype.current = function () {
        if (!this.currentOperator) {
            return this.reloadOperator();
        }
        else {
            return Observable.of(this.currentOperator);
        }
    };
    OperatorService.prototype.setOperator = function (operator) {
        this.currentOperator = operator;
        this._operator.next(this.currentOperator);
    };
    OperatorService.prototype.clear = function () {
        this.currentOperator = null;
        this._operator.next(null);
    };
    OperatorService.prototype.reloadOperator = function () {
        var _this = this;
        return this.http.get('operators/current')
            .pipe(map(function (response) {
            if (response.success) {
                return new Operator(response.data);
            }
            else {
                return null;
            }
        }))
            .pipe(map(function (operator) {
            _this.setOperator(operator);
            return _this.currentOperator;
        }));
    };
    OperatorService.prototype.getPaginationResult = function (params) {
        var _this = this;
        return this.http
            .get('operators', { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (operator) {
                return new Operator(operator);
            });
            _this.pagination_cache = pagination_result.data;
            return new PaginationResult(pagination_result);
        }));
    };
    OperatorService.prototype.get = function (id) {
        // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
        // if (user) return of(user);
        return this.http.get("operators/" + id).pipe(map(function (response) { return new Operator(response); }));
    };
    OperatorService.prototype.getAll = function () {
        return this.http
            .get("operators")
            .pipe(map(function (data) {
            return data.map(function (operator) { return new Operator(operator); });
        }));
    };
    OperatorService.prototype.save = function (operator) {
        var method = operator.id ? 'put' : 'post';
        var url = 'operators';
        if (method === 'put') {
            url += '/' + operator.id;
        }
        return this.http.request(method, url, { body: operator });
    };
    OperatorService.prototype.delete = function (operator) {
        return this.http.delete("operators/" + operator.id);
    };
    OperatorService.prototype.clearPassword = function (user, password) {
        return this.http.put("operators/" + user.id + "/password", { password: password });
    };
    OperatorService.prototype.changeStatus = function (operator, enabled) {
        return this.http.put("operators/" + operator.id + "/status/" + enabled, {});
    };
    OperatorService.prototype.addOperatorToBranch = function (operator, branch) {
        return this.http.post("branches/" + branch.id + "/operator/" + operator.id, {});
    };
    OperatorService.prototype.deleteOperatorToBranch = function (operator, branch) {
        return this.http.delete("branches/" + branch.id + "/operator/" + operator.id);
    };
    OperatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperatorService_Factory() { return new OperatorService(i0.ɵɵinject(i1.HttpClient)); }, token: OperatorService, providedIn: "root" });
    return OperatorService;
}());
export { OperatorService };
