import * as moment from 'moment';
import { ClientStatus } from './_enums/client_status';
import { User } from './user.model';
import { Level } from './level.model';
var Client = /** @class */ (function () {
    function Client(init) {
        var _this = this;
        this.affiliate_card = '';
        this.status = ClientStatus.Active;
        this.showDescription = function () {
            if (!_this.user) {
                return '';
            }
            var firstName = _this.user.first_name ? _this.user.first_name : '';
            var email = _this.user.email ? _this.user.email : '';
            var dni = _this.user.dni ? _this.user.dni : '';
            var lastName = _this.user.last_name ? _this.user.last_name : '';
            return firstName + " " + lastName + " " + email + " " + dni;
        };
        Object.assign(this, init);
        if (this.transaction_date) {
            this.transaction_date = moment(this.transaction_date);
        }
        if (init && init.level) {
            this.level = new Level(init.level);
        }
        if (init && init.user) {
            this.user = new User(init.user);
        }
        else {
            this.user = new User();
        }
    }
    Object.defineProperty(Client.prototype, "Fullname", {
        get: function () {
            if (!this.user)
                return '';
            return this.user.first_name + " " + this.user.last_name;
        },
        enumerable: true,
        configurable: true
    });
    return Client;
}());
export { Client };
