import * as tslib_1 from "tslib";
import { NbAuthResult, NbOAuth2AuthStrategy, NbOAuth2GrantType, } from '@nebular/auth';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BusinessService } from '../../../_services/business.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
var Oauth2AuthSocialStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(Oauth2AuthSocialStrategy, _super);
    function Oauth2AuthSocialStrategy(http, route, window, businessService) {
        var _this = _super.call(this, http, route, window) || this;
        _this.http = http;
        _this.route = route;
        _this.window = window;
        _this.businessService = businessService;
        return _this;
    }
    Oauth2AuthSocialStrategy.prototype.authenticate = function (data) {
        var _this = this;
        if (this.getOption('token.grantType') === NbOAuth2GrantType.PASSWORD) {
            return this.passwordToken(data.email, data.password);
        }
        else {
            return this
                .isRedirectResult()
                .pipe(switchMap(function (result) {
                if (!result) {
                    _this.authorizeRedirect();
                    return observableOf(new NbAuthResult(true));
                }
                return _this
                    .getAuthorizationResult()
                    .pipe(switchMap(function (authResult) { return _this.http
                    .post('oauth/token', {
                    client_id: environment.oauthClientId,
                    client_secret: environment.oauthClientSecret,
                    grant_type: 'social',
                    provider: _this.getName(),
                    access_token: authResult.response.access_token,
                })
                    .pipe(map(function (response) { return new NbAuthResult(true, response, _this.getOption('redirect.success'), [], _this.getOption('defaultMessages'), _this.createToken(response, true)); })); }));
            }));
        }
    };
    Oauth2AuthSocialStrategy.prototype.buildRedirectUrl = function () {
        var parts = this.getOption('authorize.redirectUri').split('//');
        var subdomain = this.businessService.getSubdomain();
        var redirectUri = subdomain ? parts[0] + '//' + subdomain + '.' + parts[1] : this.getOption('authorize.redirectUri');
        var params = tslib_1.__assign({ response_type: this.getOption('authorize.responseType'), client_id: this.getOption('clientId'), redirect_uri: redirectUri, scope: this.getOption('authorize.scope') }, this.getOption('authorize.params'));
        var endpoint = this.getActionEndpoint('authorize');
        var query = this.urlEncodeParameters(this.cleanParams(params));
        return endpoint + "?" + query;
    };
    Oauth2AuthSocialStrategy.prototype.logout = function () {
        return Observable.of(new NbAuthResult(true, {}, '/auth/login'));
    };
    return Oauth2AuthSocialStrategy;
}(NbOAuth2AuthStrategy));
export { Oauth2AuthSocialStrategy };
var Oauth2AuthFacebookStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(Oauth2AuthFacebookStrategy, _super);
    function Oauth2AuthFacebookStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Oauth2AuthFacebookStrategy.setup = function (options) {
        return [Oauth2AuthFacebookStrategy, options];
    };
    return Oauth2AuthFacebookStrategy;
}(Oauth2AuthSocialStrategy));
export { Oauth2AuthFacebookStrategy };
var Oauth2AuthGoogleStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(Oauth2AuthGoogleStrategy, _super);
    function Oauth2AuthGoogleStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Oauth2AuthGoogleStrategy.setup = function (options) {
        return [Oauth2AuthGoogleStrategy, options];
    };
    return Oauth2AuthGoogleStrategy;
}(Oauth2AuthSocialStrategy));
export { Oauth2AuthGoogleStrategy };
