import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionHistory = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionHistory, _super);
    function PermissionHistory() {
        var _this = _super.call(this) || this;
        _this.show_only_branch = false;
        return _this;
    }
    return PermissionHistory;
}(PermissionBase));
export { PermissionHistory };
