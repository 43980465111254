import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Client } from '../../_models/client.model';
var AddEmailComponent = /** @class */ (function () {
    function AddEmailComponent(ref, formBuilder) {
        this.ref = ref;
        this.formBuilder = formBuilder;
        this.title = 'Asociar un email al usuario';
        this.acceptButtonText = 'Asignar';
        this.acceptButtonStatus = 'primary';
        this.cancelButtonText = 'Cancelar';
        this.cancelButtonStatus = 'basic';
        this.useDniAsPassword = true;
        this.askName = false;
        this.submitted = false;
    }
    AddEmailComponent.prototype.ngOnInit = function () {
        var group = {
            email: ['', [Validators.required, Validators.email]],
        };
        if (this.useDniAsPassword) {
            group.dni = [this.client && this.client.user.dni ? this.client.user.dni : '', [Validators.required, Validators.minLength(7), Validators.maxLength(11)]];
        }
        else {
            group.password = ['', [Validators.required]];
        }
        if (this.askName) {
            group.first_name = ['', [Validators.required]];
            group.last_name = ['', [Validators.required]];
        }
        this.form = this.formBuilder.group(group);
    };
    Object.defineProperty(AddEmailComponent.prototype, "controls", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    AddEmailComponent.prototype.save = function () {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.accept(this.form.value);
    };
    AddEmailComponent.prototype.cancel = function () {
        this.ref.close(null);
    };
    AddEmailComponent.prototype.accept = function (value) {
        this.ref.close(value);
    };
    return AddEmailComponent;
}());
export { AddEmailComponent };
