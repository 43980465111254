import { Business } from './business.model';
export var GenderType;
(function (GenderType) {
    GenderType[GenderType["male"] = 0] = "male";
    GenderType[GenderType["female"] = 1] = "female";
    GenderType[GenderType["other"] = 2] = "other";
    GenderType[GenderType["noData"] = 3] = "noData";
})(GenderType || (GenderType = {}));
var User = /** @class */ (function () {
    function User(init) {
        var _this = this;
        // branches: BusinessBranch[];
        this.score_amount_ratio = undefined;
        this.validEmail = false;
        this.validDni = false;
        this.showDescription = function () {
            var firstName = _this.first_name ? _this.first_name : '';
            var email = _this.email ? _this.email : '';
            var dni = _this.dni ? _this.dni : '';
            var lastName = _this.last_name ? _this.last_name : '';
            return firstName + " " + lastName + " " + email + " " + dni;
        };
        Object.assign(this, init);
        if (init && init.business) {
            this.business = new Business(init.business);
        }
    }
    User.prototype.validateEmail = function () {
        if (!this.email || this.email.length === 0) {
            return false;
        }
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(this.email);
    };
    User.prototype.validateDni = function () {
        var numericRegex = /^[0-9]+$/;
        if (!this.dni || this.dni.length < 7 || this.dni.length > 11 || !numericRegex.test(this.dni)) {
            return false;
        }
        else {
            return true;
        }
    };
    Object.defineProperty(User.prototype, "fullName", {
        get: function () {
            return this.first_name + " " + this.last_name;
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };
