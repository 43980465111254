import { BusinessService } from '../../../_services/business.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../_services/business.service";
var BusinessesCurrentForceResolver = /** @class */ (function () {
    function BusinessesCurrentForceResolver(businessService) {
        this.businessService = businessService;
    }
    BusinessesCurrentForceResolver.prototype.resolve = function (route) {
        return this.businessService.getCurrent(true);
    };
    BusinessesCurrentForceResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessesCurrentForceResolver_Factory() { return new BusinessesCurrentForceResolver(i0.ɵɵinject(i1.BusinessService)); }, token: BusinessesCurrentForceResolver, providedIn: "root" });
    return BusinessesCurrentForceResolver;
}());
export { BusinessesCurrentForceResolver };
