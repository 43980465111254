import * as tslib_1 from "tslib";
import { NbRequestPasswordComponent } from '@nebular/auth';
var RequestPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestPasswordComponent, _super);
    function RequestPasswordComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestPasswordComponent;
}(NbRequestPasswordComponent));
export { RequestPasswordComponent };
