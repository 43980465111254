import * as tslib_1 from "tslib";
import { NbResetPasswordComponent } from '@nebular/auth';
var ResetPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResetPasswordComponent, _super);
    function ResetPasswordComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ResetPasswordComponent;
}(NbResetPasswordComponent));
export { ResetPasswordComponent };
