import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionClients = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionClients, _super);
    function PermissionClients() {
        var _this = _super.call(this) || this;
        _this.can_delete = false;
        _this.can_edit = false;
        _this.can_substraction_points = false;
        _this.can_send_reactivate_account_mail = false;
        _this.can_associate_email = false;
        _this.can_create_link_regenerate_password = false;
        _this.can_change_status = false;
        _this.can_change_role = false;
        return _this;
    }
    return PermissionClients;
}(PermissionBase));
export { PermissionClients };
