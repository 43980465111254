import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionSurvey = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionSurvey, _super);
    function PermissionSurvey() {
        return _super.call(this) || this;
    }
    return PermissionSurvey;
}(PermissionBase));
export { PermissionSurvey };
