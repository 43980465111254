import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ProductService } from '../../_services/product.service';
import { ProductBusiness } from '../../_models/product_business.model';
var AddProductToPlanComponent = /** @class */ (function () {
    function AddProductToPlanComponent(ref, formBuilder, toastrService, productService) {
        this.ref = ref;
        this.formBuilder = formBuilder;
        this.toastrService = toastrService;
        this.productService = productService;
        this.acceptButtonStatus = 'success';
        this.cancelButtonStatus = 'basic';
        this.onScoreUpdate = new EventEmitter();
        this.saving = false;
        this.submitted = false;
        this.form = this.formBuilder.group({
            score: ['', [Validators.required]],
            level: ['', []],
            description: ['', []],
        });
    }
    Object.defineProperty(AddProductToPlanComponent.prototype, "controls", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    AddProductToPlanComponent.prototype.ngOnInit = function () {
        if (this.create) {
            this.title = 'Añadir premio a tu plan';
            this.textAddButton = 'Añadir';
        }
        else {
            this.title = 'Editar puntos necesarios';
            this.textAddButton = 'Aceptar';
        }
    };
    AddProductToPlanComponent.prototype.save = function () {
        var _this = this;
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.saving = true;
        if (this.create) {
            var pb = { 'business_id': this.businessId, 'product_id': this.productId, 'score': this.form.value.score, 'description': '', level_id: this.form.value.level };
            this.productService.addToPlan(new ProductBusiness(pb)).subscribe(function () {
                _this.toastrService.success('El producto fue añadido con exito!.', '¡Bien!');
                _this.accept();
            }, function (err) {
                _this.toastrService.danger(err.error.error, 'Error');
                _this.saving = false;
            });
        }
        else {
            this.productService.patchProductBusiness(this.productId, this.businessId, { score: this.form.value.score, 'description': '', level_id: this.form.value.level }).subscribe(function () {
                _this.toastrService.success('El producto fue editado con exito!.', '¡Bien!');
                _this.onScoreUpdate.emit(_this.form.value.score);
                _this.accept();
            }, function (err) {
                _this.toastrService.danger(err.error.error, 'Error');
                _this.saving = false;
            });
        }
    };
    AddProductToPlanComponent.prototype.cancel = function () {
        this.ref.close(false);
    };
    AddProductToPlanComponent.prototype.accept = function () {
        this.ref.close(true);
    };
    return AddProductToPlanComponent;
}());
export { AddProductToPlanComponent };
