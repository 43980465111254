import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionStatistic = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionStatistic, _super);
    function PermissionStatistic() {
        return _super.call(this) || this;
    }
    return PermissionStatistic;
}(PermissionBase));
export { PermissionStatistic };
