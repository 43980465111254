import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionHistoryProvider = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionHistoryProvider, _super);
    function PermissionHistoryProvider() {
        return _super.call(this) || this;
    }
    return PermissionHistoryProvider;
}(PermissionBase));
export { PermissionHistoryProvider };
