import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Product } from '../_models/product.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { ProductOffer } from '../_models/product_offer.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProductService = /** @class */ (function () {
    function ProductService(http) {
        var _this = this;
        this.http = http;
        this.reloadCategories = new EventEmitter();
        this.search = function (params) {
            return _this.http.get('products/search', { params: params });
        };
    }
    ProductService.prototype.one = function (id) {
        var product = new Product();
        if (id) {
            product.id = id;
        }
        return product;
    };
    ProductService.prototype.get = function (product) {
        return this.http.get('products/' + product.id);
    };
    ProductService.prototype.getProductOffer = function (productOfferId) {
        return this.http.get('products/offer/' + productOfferId).pipe(map(function (response) {
            return new ProductOffer(response);
        }));
    };
    ProductService.prototype.getAllProductsOffers = function () {
        return this.http.get("products/offers").pipe(map(function (response) {
            return response.map(function (product) {
                return new ProductOffer(product);
            });
        }));
    };
    ProductService.prototype.delete = function (product, business) {
        return this.http.delete("products/" + product.id + "/business/" + business.id);
    };
    ProductService.prototype.getMarketplacetPaginationResult = function (params) {
        return this.http.get('products/marketplace', { params: params }).pipe(map(function (response) {
            response.data = response.data.map(function (product) {
                return new Product(product);
            });
            return new PaginationResult(response);
        }));
    };
    ProductService.prototype.getPaginationResult = function (params) {
        return this.http.get('products', { params: params }).pipe(map(function (response) {
            var products_pagination_result = response;
            products_pagination_result.data = response.data.map(function (product) {
                return new Product(product);
            });
            return new PaginationResult(products_pagination_result);
        }));
    };
    ProductService.prototype.getShopPaginationResult = function (params) {
        return this.http.get('products/shop', { params: params }).pipe(map(function (response) {
            var products_pagination_result = response;
            products_pagination_result.data = response.data.map(function (product) {
                return new Product(product);
            });
            return new PaginationResult(products_pagination_result);
        }));
    };
    ProductService.prototype.all = function (params) {
        return this.http.get('products', { params: params }).pipe(map(function (response) {
            return response.map(function (product) { return new Product(product); });
        }));
    };
    ProductService.prototype.save = function (product, categoryChanges) {
        var method = product.id ? 'put' : 'post';
        var url = 'products';
        if (method === 'put') {
            url += '/' + product.id;
        }
        return this.http.request(method, url, {
            body: tslib_1.__assign({}, product, { categoryChanges: categoryChanges }),
        });
    };
    ProductService.prototype.addToPlan = function (productBusiness) {
        return this.http.request('post', 'products/shop', {
            body: productBusiness,
        });
    };
    ProductService.prototype.removeToPlan = function (product, business) {
        return this.http.delete("products/shop/" + product.id + "/business/" + business.id);
    };
    ProductService.prototype.patch = function (product, params) {
        return this.http.patch('products/' + product.id, params);
    };
    ProductService.prototype.activateProductBusiness = function (product, business, active) {
        return this.http.patch("products/shop/" + product.id + "/business/" + business.id + "/activate/" + active, null);
    };
    ProductService.prototype.patchProductBusiness = function (productId, businessId, params) {
        return this.http.patch("products/shop/" + productId + "/business/" + businessId, params);
    };
    ProductService.prototype.getAllCategories = function () {
        return this.http.get('products/categories');
    };
    ProductService.prototype.uploadCoupons = function (id, file) {
        var formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post("products/loadsheet/" + id + "/coupons", formData);
    };
    ProductService.prototype.getProductCoupon = function (coupon) {
        return this.http.get("products/coupon/" + coupon.id);
    };
    ProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
