import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tag } from '../_models/tag.model';
import { DownloadService } from '../shared/download/download.service';
import { Read } from '../_models/read.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { ProductOffer } from '../_models/product_offer.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/download/download.service";
var TagService = /** @class */ (function () {
    function TagService(http, downloadService) {
        this.http = http;
        this.downloadService = downloadService;
    }
    TagService.prototype.one = function (id) {
        var tag = new Tag();
        if (id) {
            tag.id = id;
        }
        return tag;
    };
    TagService.prototype.get = function (tag) {
        return this.http.get('tags/' + tag.id)
            .pipe(map(function (response) { return new Tag(response); }));
    };
    TagService.prototype.getPaginationResult = function (params) {
        return this.http
            .get('tags', { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (tag) {
                return new Tag(tag);
            });
            return new PaginationResult(pagination_result);
        }));
    };
    TagService.prototype.all = function (params) {
        return this.http.get('tags', { params: params })
            .pipe(map(function (response) {
            return response.map(function (tag) { return new Tag(tag); });
        }));
    };
    TagService.prototype.save = function (tag) {
        var method = tag.id ? 'put' : 'post';
        var url = 'tags';
        if (method === 'put') {
            url += '/' + tag.id;
            return this.http.put(url, tag);
        }
        return this.http.post(url, tag);
    };
    TagService.prototype.read = function (id) {
        var url = "read/" + id;
        return this.http
            .get(url, { headers: { 'Accept': 'application/json' } })
            .pipe(map(function (r) { return ({
            read: new Read(r.read),
            nextProductOffer: r.nextProductOffer ? new ProductOffer(r.nextProductOffer) : null,
        }); }));
    };
    TagService.prototype.delete = function (tag) {
        var url = "tags/" + tag.id;
        return this.http.delete(url);
    };
    TagService.prototype.download = function (tag) {
        var _this = this;
        return this.http
            .get('tags/' + tag.id + '/print.pdf', { responseType: 'blob' })
            .subscribe(function (response) {
            _this.downloadService.download('tdp-qr-' + tag.code + '.pdf', response, '');
        });
    };
    TagService.prototype.substract = function (data) {
        var method = 'post';
        var url = 'tags/substraction';
        return this.http.request(method, url, { body: data });
    };
    TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DownloadService)); }, token: TagService, providedIn: "root" });
    return TagService;
}());
export { TagService };
