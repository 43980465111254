import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionExchange = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionExchange, _super);
    function PermissionExchange() {
        var _this = _super.call(this) || this;
        _this.allow_exchange_products = false;
        _this.allow_exchange_money = false;
        return _this;
    }
    return PermissionExchange;
}(PermissionBase));
export { PermissionExchange };
