import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Integration } from '../_models/integrations/integration.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IntegrationService = /** @class */ (function () {
    function IntegrationService(http) {
        this.http = http;
    }
    IntegrationService.prototype.save = function (integration) {
        var method = integration.id ? 'put' : 'post';
        var url = "integrations";
        if (method === 'put') {
            url += "/" + integration.id;
        }
        return this.http.request(method, url, { body: integration });
    };
    IntegrationService.prototype.getAll = function (params) {
        return this.http.get("integrations", { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (integration) {
                return new Integration(integration);
            });
            return new PaginationResult(pagination_result);
        }));
    };
    IntegrationService.prototype.get = function (integrationId) {
        return this.http.get("integrations/" + integrationId);
    };
    IntegrationService.prototype.delete = function (integration) {
        return this.http.delete("integrations/" + integration.id);
    };
    IntegrationService.prototype.changeStatus = function (integration, enabled) {
        return this.http.put("integrations/" + integration.id + "/status/" + enabled, {});
    };
    IntegrationService.prototype.authDragonFish = function (data) {
        return this.http.post("integrations/dragonfish/auth", data);
    };
    IntegrationService.prototype.healthCheck = function (id) {
        return this.http.get("integrations/" + id + "/health");
    };
    IntegrationService.prototype.authFudo = function (data) {
        return this.http.post("integrations/fudo/auth", data);
    };
    IntegrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntegrationService_Factory() { return new IntegrationService(i0.ɵɵinject(i1.HttpClient)); }, token: IntegrationService, providedIn: "root" });
    return IntegrationService;
}());
export { IntegrationService };
