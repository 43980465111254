import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionIntegration = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionIntegration, _super);
    function PermissionIntegration() {
        var _this = _super.call(this) || this;
        _this.mercado_pago = false;
        _this.mercado_pago_webhook = false;
        _this.payway = false;
        _this.dragon_fish = false;
        _this.centum = false;
        return _this;
    }
    return PermissionIntegration;
}(PermissionBase));
export { PermissionIntegration };
