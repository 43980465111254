import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
var PermissionLevel = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionLevel, _super);
    function PermissionLevel() {
        return _super.call(this) || this;
    }
    return PermissionLevel;
}(PermissionBase));
export { PermissionLevel };
