import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Plan } from '../_models/plan.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PlanService = /** @class */ (function () {
    function PlanService(http) {
        var _this = this;
        this.http = http;
        this.pagination_cache = [];
        this.search = function (query) {
            var params = { query: query };
            return _this.http.get('business-plans/search', { params: params });
        };
    }
    PlanService.prototype.getPaginationResult = function (params) {
        var _this = this;
        return this.http
            .get('business-plans', { params: params })
            .pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (plan) {
                return new Plan(plan);
            });
            _this.pagination_cache = pagination_result.data;
            return new PaginationResult(pagination_result);
        }));
    };
    PlanService.prototype.get = function (id) {
        // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
        // if (user) return of(user);
        return this.http.get("business-plans/" + id)
            .pipe(map(function (response) { return new Plan(response); }));
    };
    PlanService.prototype.save = function (plan) {
        var method = plan.id ? 'put' : 'post';
        var url = 'business-plans';
        if (method === 'put') {
            url += '/' + plan.id;
        }
        return this.http.request(method, url, { body: plan });
    };
    PlanService.prototype.delete = function (planSourceId, planDestinationId) {
        return this.http.request('delete', "business-plans/" + planSourceId, { body: { destination_plan_id: planDestinationId } });
    };
    PlanService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanService_Factory() { return new PlanService(i0.ɵɵinject(i1.HttpClient)); }, token: PlanService, providedIn: "root" });
    return PlanService;
}());
export { PlanService };
